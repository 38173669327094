import styled from 'styled-components';
import theme from '../../constants/theme';

export default styled.blockquote`
    margin: 8rem 0 8rem -2.8rem;
    padding: 0 0 0 2.8rem;
    border-left: 2px solid ${theme.colors.codGray};

    @media screen and (${theme.mediaQueries.vertical}) {
        display: block;
        margin: 4rem -2.8rem 4rem 0;
        padding: 0 2.8rem;
    }
`;
