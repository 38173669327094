import HTMLParser, { processNodes } from 'react-html-parser';
import * as React from 'react';
import { ComponentType, FC, Fragment } from 'react';
import { Link } from 'gatsby';
import { DomElement } from 'htmlparser2';
import BodyText, { BodyVariant } from './BodyText';
import URL from 'url-parse';
import { TitleVariant } from './Title';
import ArticleQuote from './ArticleQuote';
import { SiteContext } from '../../contexts/SiteContext';

type VariantType = BodyVariant | TitleVariant;

interface Props {
    html: string;
    paragraphWrapper?: ComponentType;
    variant?: VariantType;
}

const { hostname: publicHostname } = new URL(process.env.GATSBY_PUBLIC_URL || '');

const transform = (ParagraphWrapper: ComponentType<{ variant?: VariantType }>, site: 'en' | 'nl' | undefined, variant?: VariantType) => (
    node: DomElement,
    index: number
) => {
    // check if this is a link
    if (node.name === 'a' && node.attribs && node.attribs.target !== '_blank') {
        const { hostname, pathname, query, hash } = new URL(node.attribs.href);

        let isInternal = hostname === publicHostname;

        // when running locally, we assume everything that ends with oberon.nl is a internal link.
        if (publicHostname === 'localhost' && hostname.endsWith('oberon.nl')) {
            isInternal = true;
        }

        return isInternal ? (
            <Link to={`/${site}/${pathname}/${query}${hash}`} key={index}>
                {processNodes(node.children || [], transform(ParagraphWrapper, site, variant))}
            </Link>
        ) : undefined;
    }

    if (node.name === 'em') {
        node.name = 'span';
    }

    if (node.name === 'li') {
        return (
            <li>
                <ParagraphWrapper key={index} variant={variant}>
                    {processNodes(node.children || [], transform(ParagraphWrapper, site, variant))}
                </ParagraphWrapper>
            </li>
        );
    }

    if (node.name === 'p') {
        // if node.name has em change it to a blockquote (for the NewsArticle Conversion)
        const hasEm = node.children && node.children.find((item: DomElement) => item.name === 'em');
        const renderAdded = [];
        const childIndex: number[] = [];
        if (node.children) {
            if (hasEm) {
                childIndex.push(node.children.indexOf(hasEm));
                node.children.splice(node.children.indexOf(hasEm));
                renderAdded.push(processNodes([hasEm], transform(ParagraphWrapper, site)));
            }
        }
        // render a BodyText instead of a p
        return (
            <Fragment key={index}>
                <ParagraphWrapper variant={variant}>
                    {processNodes(node.children || [], transform(ParagraphWrapper, site, variant))}
                </ParagraphWrapper>
                {renderAdded &&
                    renderAdded.map((item, i) => (
                        <ArticleQuote key={childIndex[i]}>
                            <BodyText variant={'large'}>{item}</BodyText>
                        </ArticleQuote>
                    ))}
            </Fragment>
        );
    }

    // parse tag normally
    return undefined;
};

const CmsContent: FC<Props> = ({ html, paragraphWrapper = BodyText, variant }) => {
    const site = React.useContext(SiteContext);
    return (
    <>
        {HTMLParser(html, {
            decodeEntities: true,
            transform: transform(paragraphWrapper, site, variant),
        })}
    </>
)};

export default CmsContent;
